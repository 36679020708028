<script>
export default {
  props: {
    nominaLiquidacionPersonaDetalle: {

    }
  }
}

</script>

<template>

  <div class="row px-5">
    <div class="col-md-12">
      <!-- {{nominaLiquidacionPersonaDetalle}} -->
      <div class="m-4 table-responsive" style="height: 50vh; overflow: scroll">
        <table class="table">
          <thead>
            <tr>
              <!-- <th scope="col">conceptoId</th> -->
              <th scope="col">Fecha inicio</th>
              <th scope="col">Fecha fin</th>
              <th scope="col">Concepto</th>
              <th scope="col">base liquida</th>
              <th scope="col">porcentaje trabajador</th>
              <th scope="col">valor trabajador</th>
              <th scope="col">porcentaje empleador</th>
              <th scope="col">valor empleador</th>
              <th scope="col">Devengado</th>
              <th scope="col">Deducción</th>
              <!-- <th scope="col">Opciones</th> -->
            </tr>
          </thead>
          <tbody v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
            <tr>
              <!-- <th scope="row">{{ itemTable.conceptoId }}</th> -->
              <td>{{ itemTable.fechaInicio != null ? $moment(itemTable.fechaInicio).format("DD-MM-YYYY HH:mm") : "--"
                }}</td>
              <td>{{ itemTable.fechaFin != null ? $moment(itemTable.fechaFin).format("DD-MM-YYYY HH:mm") : "--" }}
              </td>

              <td>{{ itemTable.concepto }}</td>
              <td>
                {{
                  Number(itemTable.baseLiquida).toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
                }}
              </td>
              <td>
                {{ Number(itemTable.porcentajeTrabajador).toLocaleString("es") }}
              </td>
              <td>
                {{
                  Number(itemTable.valorTrabajador).toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
                }}
              </td>
              <td>
                {{ Number(itemTable.porcentajeEmpleador).toLocaleString("es") }}
              </td>
              <td>
                {{
                  Number(itemTable.valorEmpleador).toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
                }}
              </td>
              <td>
                {{
                  itemTable.tDev
                    ? Number(itemTable.tDev).toLocaleString("es-CO", {
                      style: "currency",
                      currency: "COP",
                    })
                    : 0
                }}
              </td>
              <td>
                {{
                  itemTable.tDed
                    ? Number(itemTable.tDed).toLocaleString("es-CO", {
                      style: "currency",
                      currency: "COP",
                    })
                    : 0
                }}
              </td>
              <td>
                <!-- <i
                      v-if="!['Salario', 'Auxilio de transporte', , 'Salud', 'Fondo de pensión'].includes(itemTable.concepto)"
                      @click="eliminarItem(itemTable)"
                      class="fas fa-solid fa-trash px-1 text-danger"
                    ></i> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mb-3">
        <div class="col 6">
          Devengados:
          {{
            nominaLiquidacionPersonaDetalle
              .reduce((prev, curr) => prev + Number(curr.tDev), 0)
              .toLocaleString("es-CO", {
                style: "currency",
                currency: "COP",
              })
          }}
        </div>
        <div class="col 6">
          Deducciones:
          {{
            nominaLiquidacionPersonaDetalle
              .reduce((prev, curr) => prev + Number(curr.tDed), 0)
              .toLocaleString("es-CO", {
                style: "currency",
                currency: "COP",
              })
          }}
        </div>
        <div class="col 6">
          Total:
          {{
            (
              nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0) -
              nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDed), 0)
            ).toLocaleString("es-CO", {
              style: "currency",
              currency: "COP",
            })
          }}
        </div>
      </div>
      <div class="text-right px-5 pb-4">
        <!-- <button @click.prevent="guardarDataUsuario()" type="button" class="mx-1 btn px-4 py-2 btn-primary">Guardar</button>
              <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/numeraciones')">Cancelar</button> -->
      </div>
    </div>
  </div>
</template>